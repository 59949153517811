import React from 'react'
import logo from "../images/storees-logo.png";
import graph from "../images/graph.png";
import StepProgress from './StepProgess';
import RotatingText from './RotatingText';

export default function SuperCharge() {
  return (
  <>
    <div className="w-3/5 px-10 py-5 hidden xl:block 2xl:block">
      <img src={logo} alt="Storees Logo" className="w-36" />
      <div className="relative h-5/6 w-full mt-10">
        <h1 className="text-4xl font-bold absolute top-10">
          Supercharged <RotatingText/> <br />
          For Smarter Sales Strategy.
        </h1>

        <h2 className="text-4xl font-semibold text-[#0221CF] absolute top-48 left-0">
          Launching Soon
        </h2>

        <div
          className="h-full w-full bg-cover bg-no-repeat bg-center bg-contain"
          style={{ backgroundImage: `url(${graph})` }}
        >
        </div>
      </div>
    </div>

    <div className="display-block py-16 px-10 xl:hidden 2xl:hidden">
      <img src={logo} alt="Storees Logo" className="w-36" />
      <div className="mt-10 mb-10 text-4xl font-bold space-y-3">
        <span className='inline-block'>Supercharged</span> 
        <br></br>
        <span className="inline-block"><RotatingText/></span> 
        <br></br>
        <span className="inline-block">For Smarter</span> 
        <br></br>
        <span className="inline-block">Sales Strategy.</span> 
      </div>

      <h2 className="text-3xl font-semibold text-[#0221CF]">
        Launching Soon
      </h2>
      <StepProgress/>
    </div>
  </>
  );
}
