import React from 'react';
import "./EndPage.css";
import tick from "../images/tick-animation.gif"


const EndPage = () => {
  return (
   <div className='bg-[#021DB2] flex items-center justify-center h-screen endpage-background'>
     <div className="bg-white py-8 px-6 lg:px-16 lg:py-8 rounded-xl shadow-lg">
        <img src={tick} alt="success" width={100} className='m-auto'></img>
        <p className="text-2xl font-bold text-center">Thank You!</p>
        <p className='font-popins pt-2 text-center text-lg'>Your Submission Has Been Sent</p> 
     </div>
    </div>
  );
};

export default EndPage;
