import React, { useState} from 'react';
import "./CRMForm.css";
import countryCodes from '../utils/countryCodes.json';
import Error from '../utils/Error';
import { useNavigate } from "react-router-dom";


const CRMForm = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    countryCode: '+91',  // default country code
    mobile: '',
    business: '',
    message: ''
  });

  const [errors, setErrors] = useState({});
  const [invalidEmail, setInvalidEmail] = useState('');

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    setErrors({})
  };

  function debounce(fn, delay) {
    let timeoutID;
    return function(...args) {
      if (timeoutID) clearTimeout(timeoutID);
      timeoutID = setTimeout(() => fn(...args), delay);
    };
  }
  
  const handleEmailChange = debounce((value) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(value)) {
        setInvalidEmail('Invalid email');
    } else {
        setInvalidEmail(''); // Clear the error message if the email is valid
    }
}, 1000); //1s delay

  const validate =() =>{
    const newErrors = {};
    if(!formData.name) newErrors.name = 'Name is required'
    if(!formData.email) newErrors.email = 'Email is required'
    if(!formData.mobile) newErrors.mobile = 'Mobile is required'
    if(!formData.business) newErrors.business = 'Business is required'

    return newErrors
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validate();
    if(invalidEmail) return
    if(Object.keys(formErrors).length === 0){
      try{
      setLoading(true)
      const response = await fetch('https://script.google.com/macros/s/AKfycbwkGSTWKaHx2kvEoP-L6JAxANNBrqptzud4qlHv1lGBF9Vccn_Koi3Iuy0SOpFci0ND/exec', {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'text/plain'
        }
      });
      setLoading(false)
      if (response.ok) {
        setErrors({});
        setFormData({
          name: '',
          email: '',
          countryCode: '+91',
          mobile: '',
          message: ''
        });
        navigate("/thank-you")
      } else {
        alert('There was a problem submitting the data');
      }
      }catch(error){
        console.log('Failed to submit form',error);
      }
    }else{
      setErrors(formErrors)
    }
  };

  return (
    <div className='bg-[#021DB2] w-full xl:w-2/5 flex items-center justify-center py-24 px-5 xl:py-0 xl:px-0 form-background'>
     <div className="bg-white py-8 px-8 xl:py-4 xl:px-8 rounded-lg shadow-lg xl:h-[calc(100vh-80px)] xl:overflow-y-auto">
      <h2 className="text-2xl font-bold mb-6 text-center">Enquiry Form</h2>
      <form onSubmit={handleSubmit} className='bg-[#FFFFFF] space-y-3'>
        <div className='flex flex-col'>
        <label className='font-popins text-base font-semibold'>
          Name <span className='text-blue-600'>*</span>
        </label>
        <input 
            type="text" 
            className="w-full px-4 py-2 border rounded-lg outline-none"
            name="name" 
            value={formData.name} 
            onChange={handleChange} 
            maxLength={100}
            placeholder='Your name'
          />
          {errors.name &&  <Error text={errors.name}/>}
        </div>
        <div className='flex flex-col'>
          <label className='font-popins text-base font-semibold'>Email <span className='text-blue-600'>*</span></label>
          <input 
            type="email" 
            name="email" 
            className="w-full px-4 py-2 border rounded-lg outline-none"
            value={formData.email}
            placeholder='Your email'
            onChange={(e) => {
              handleChange(e);
              handleEmailChange(e.target.value);
            }}
          />
          {errors.email &&  <Error text={errors.email}/>}
          {invalidEmail &&  <Error text={invalidEmail}/>}
        </div>
        <div className='flex flex-col'>
          <label className='font-popins text-base font-semibold'>Mobile <span className='text-blue-600'>*</span></label>
         <div className='flex flex-col'>
          <div className='mobile-box'>
            <select
              className="pl-1 border rounded-lg country-code"
              name="countryCode"
              value={formData.countryCode}
              onChange={handleChange}
              required
            >
              {countryCodes.map((country, i) => (
                <option key={i} value={country.code}>
                  {country.code}
                </option>
              ))}
            </select>
            <input
              className="px-4 py-2 border rounded-lg mobile-input w-full"
              type="number"
              name="mobile"
              placeholder='9856241264'
              value={formData.mobile} 
              onChange={(e) =>{
                const value = e.target.value;
                if(value.length <= 10){
                  handleChange(e)
                }
              }}
            />
           </div>
           {errors.mobile &&  <Error text={errors.mobile}/>}
          </div>
        </div>
        <div className='flex flex-col'>
          <label className='font-popins text-base font-semibold'>Business <span className='text-blue-600'>*</span></label>
          <input 
            type="text" 
            className="w-full px-4 py-2 border rounded-lg outline-none"
            name="business" 
            value={formData.business} 
            onChange={handleChange} 
            maxLength={100}
          />
          {errors.business &&  <Error text={errors.business}/>}
        </div>
        <div>
          <label className='font-popins text-base font-semibold'>Message</label>
          <textarea 
            name="message"
            className="w-full px-4 py-2 border rounded-lg outline-none"
            value={formData.message} 
            onChange={handleChange} 
            maxLength={300}
            rows={3}
          />
        </div>
        <div className='w-full flex justify-center'>
          <button type="submit" className="bg-blue-800 hover:bg-blue-900 px-12 py-2 rounded text-white bg-[#0220C6] font-bold" disabled={loading}>{loading ? "Submitting..." : "Submit"}</button>
        </div>
      </form>
     </div>
    </div>
  );
};

export default CRMForm;
