// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/mask-group.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-box{
    display: flex;
}
.mobile-input{
    border-left: none;
    outline: none;
    border-radius: 0px 5px 5px 0px;
}
.country-code{
    border-radius: 5px 0 0 5px;
    outline: none;
    border-right: none;
}
.form-background{
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-position: top;
}`, "",{"version":3,"sources":["webpack://./src/Components/CRMForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,iBAAiB;IACjB,aAAa;IACb,8BAA8B;AAClC;AACA;IACI,0BAA0B;IAC1B,aAAa;IACb,kBAAkB;AACtB;AACA;IACI,yDAAiD;IACjD,4BAA4B;IAC5B,wBAAwB;AAC5B","sourcesContent":[".mobile-box{\n    display: flex;\n}\n.mobile-input{\n    border-left: none;\n    outline: none;\n    border-radius: 0px 5px 5px 0px;\n}\n.country-code{\n    border-radius: 5px 0 0 5px;\n    outline: none;\n    border-right: none;\n}\n.form-background{\n    background-image: url(\"../images/mask-group.png\");\n    background-repeat: no-repeat;\n    background-position: top;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
