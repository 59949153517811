// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/mask-group.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.endpage-background{
    z-index: 10;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size:contain;
    background-repeat: no-repeat;
    background-position: right;
}

@media (max-width:480px){
    .endpage-background{
        background-position: bottom;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/EndPage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yDAAiD;IACjD,uBAAuB;IACvB,4BAA4B;IAC5B,0BAA0B;AAC9B;;AAEA;IACI;QACI,2BAA2B;IAC/B;AACJ","sourcesContent":[".endpage-background{\n    z-index: 10;\n    background-image: url(\"../images/mask-group.png\");\n    background-size:contain;\n    background-repeat: no-repeat;\n    background-position: right;\n}\n\n@media (max-width:480px){\n    .endpage-background{\n        background-position: bottom;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
