import React from "react";
import TextTransition, { presets } from 'react-text-transition';

const RotatingText = () => {
    const [index, setIndex] = React.useState(0);

    const TEXTS = ["ECommerce", "Executives", "Marketings"];

    React.useEffect(() => {
      const intervalId = setInterval(
        () => setIndex((index) => index + 1),
        3000, // every 3 seconds
      );
      return () => clearTimeout(intervalId);
    }, []);
  
  
    return (
        <TextTransition className='!inline-flex text-blue-700' springConfig={presets.wobbly}>{TEXTS[index % TEXTS.length]}</TextTransition>
    );
};
  
export default RotatingText;
