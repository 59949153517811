import React from "react";

const StepProgress = () => {
  return (
    <div className="flex items-center justify-center pt-10">
      {/* Step 1: Start */}
      <div className="flex items-center">
        <div className="flex items-center justify-center rounded-full border-2 border-yellow-400 bg-yellow-100 px-4 py-2">
          <span className="text-black font-semibold text-xs">Start</span>
        </div>
        <div className="border-dashed border-t-2 border-black w-5"></div>
      </div>

      {/* Step 2: Track */}
      <div className="flex items-center">
        <div className="flex items-center justify-center rounded-full border-2 border-blue-500 bg-blue-100 px-4 py-2">
          <span className="text-black font-semibold text-xs">Track</span>
        </div>
        <div className="border-dashed border-t-2 border-black w-5"></div>
      </div>

      {/* Step 3: Engage */}
      <div className="flex items-center">
        <div className="flex items-center justify-center rounded-full border-2 border-teal-400 bg-teal-100 px-4 py-2">
          <span className="text-black font-semibold text-xs">Engage</span>
        </div>
        <div className="border-dashed border-t-2 border-black w-5"></div>
      </div>

      {/* Step 4: Scale */}
      <div className="flex items-center">
        <div className="flex items-center justify-center rounded-full border-2 border-green-400 bg-green-100 px-4 py-2">
          <span className="text-black font-semibold text-xs">Scale</span>
        </div>
      </div>
    </div>
  );
};

export default StepProgress;
