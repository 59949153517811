import React from 'react'
import SuperCharge from './SuperCharge'
import CRMForm from './CRMForm'

function Homepage() {
  return (
      <div className="min-h-screen w-screen flex flex-col xl:flex-row shadow-lg overflow-hidden">
          <SuperCharge/>
          <CRMForm/>
      </div>
  )
}
export default Homepage